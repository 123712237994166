@import "reset";
@import "common";
@import "top_logo";
@import "footer";

#racing-stripe {
    background-color: #E9ECD8;
    height: 535px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

#topwrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

#topleftwrap {
    text-align: center;
}

.tagline {
    font-weight: bold;
    font-size: 2.5em;
    line-height: 1.2em;
}

#shot1div {
    text-align: center;
}

#shot1 {
    width: 250px;
    margin-top: 30px
}

.download-link {
    display: block;
    margin-top: 70px;
}

.download-link img {
    height: 80px;
}

#after-download {
    margin-top: 8px;
}

#gamify-tagline {
    margin-top: 80px;
}

#screenshots {
    margin-top: 50px;
    display: grid;
    gap: 55px;
    grid-gap: 55px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.screenshot {
    background-color: #E9ECD8;
    padding: 20px;
    text-align: center;
}

.screenshot img {
    width: 66%;
}

@media (min-width: 375px) {
    width: 360px;
}

@media (min-width: 1024px) {
    #topwrap {
        flex-direction: row;
    }

    #topleftwrap {
        text-align: left;
    }

    #shot1 {
        margin-top: -120px;
        width: 360px;
    }

    #screenshots {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .screenshot p {
        height: 80px;
    }
}
